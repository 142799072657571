import { getIterationBoard, getIterationBoardCountApi, getIterationPageListApi, updateDocBatch, updateIterationStatus } from "@/api/request-modules/iteration";
import { getWeekReportByWeekApi, getWeekReportListApi, saveWeekReportApi } from "@/api/request-modules/product";
import { ResponseParams } from "@/types/response";
import { awaitFunc } from "@/utils";

/*
 * @Author: 宋绍华
 * @Date: 2022-02-21 12:01:52
 * @LastEditTime: 2022-05-31 14:27:07
 * @LastEditors: 宋绍华
 * @Description:
 * @FilePath: \tomato-pc\src\composables\useIteration.ts
 */
export interface SaveWeekReportInter {
  iteration_id: number;
  plan: string;
  summary: string;
  week: number;
}
export interface WeekReportItemInter {
  end_date: string;
  is_fill: boolean;
  iteration_id: number;
  plan: string;
  start_date: string;
  summary: string;
  week: number;
}

export interface WeekReportItemEditInter extends WeekReportItemInter {
  next_week_plan: string;
  next_week: number;
  next_week_start_date: string;
  next_week_end_date: string;
}

export interface DemandReqItemsFile {
  create_by?: string;
  create_time?: string;
  creator?: string;
  id?: number;
  name: string;
  remark?: string;
  size: number;
  type: number; // 1 需求链接，2 需求文件
  url: string;
}
export interface DemandReqItems {
  category: number;
  quote_id: number;
  docs: DemandReqItemsFile[];
}

export interface InterDocList {
  create_time: string;
  file_name: string;
  id: number;
  preview_url: string;
  size: number;
  is_unpacked: number;
  staff_name: string;
  title: string;
  type: number;
  update_time: string;
  url: string;
}

export interface IterationBoardRespItem {
  id: number; // 迭代ID
  name: string; // 迭代名称
  status: number; // 迭代状态：0待开始，2开发中，3联调中，4测试中，6已发版
  time_percent: string; // 时间进度
  complete_percent: string; // 迭代总进度
  version: string; // 版本号
  dev_time: string; // 开发时间
  release_time: string; // 发版时间
}

export interface IterationBoardCountResp {
  pending_iteration_count: number; // 待开始迭代数量
  develop_iteration_count: number; // 开发中迭代数量
  union_iteration_count: number; // 联调中迭代数量
  test_iteration_count: number; // 测试中迭代数量
  released_iteration_count: number; // 已发布迭代数量
}

export default function useIteration() {
  // 周报保存
  const useSaveWeekReports = async (params: SaveWeekReportInter): Promise<boolean> => {
    const [err]: [unknown, ResponseParams.ResponsePermissionSuccess | null] = await awaitFunc<SaveWeekReportInter, ResponseParams.ResponsePermissionSuccess>({
      asyncFunc: saveWeekReportApi,
      args: params
    });
    if (err) return false;
    return true;
  };
  // 获取需求审批单列表
  const getWeekReportsList = async (iteration_id: number): Promise<WeekReportItemInter[] | null> => {
    const [err, data]: [unknown, any | null] = await awaitFunc<number, WeekReportItemInter[]>({
      asyncFunc: getWeekReportListApi,
      args: iteration_id
    });
    if (err || !data || (Array.isArray(data) && !data.length)) return null;
    return data;
  };
  // 获取需求审批单列表
  const getWeekReportsByWeek = async (iteration_id: number, week: number): Promise<WeekReportItemEditInter | null> => {
    const [err, data]: [unknown, any | null] = await awaitFunc<Record<string, number>, WeekReportItemEditInter>({
      asyncFunc: getWeekReportByWeekApi,
      args: { iteration_id, week }
    });
    if (err || !data) return null;
    return data;
  };

  // 编辑需求说明
  const useUpdateDemand = async (params: DemandReqItems): Promise<boolean> => {
    const [err]: [unknown, ResponseParams.ResponsePermissionSuccess | null] = await awaitFunc<DemandReqItems, ResponseParams.ResponsePermissionSuccess>({
      asyncFunc: updateDocBatch,
      args: params,
      needCode: true
    });
    // 如果需要code 200
    if (typeof err === "number") {
      return err === 200;
    } else if (err) return false;
    return true;
  };

  // 获取需求审批单列表
  const useGetIterationBoard = async (params: { product_id: number; name?: string }): Promise<IterationBoardRespItem[] | null> => {
    const [err, data]: [unknown, IterationBoardRespItem[] | null] = await awaitFunc<{ product_id: number; name?: string }, IterationBoardRespItem[]>({
      asyncFunc: getIterationBoard,
      args: params
    });
    if (err || !data) return null;
    return data;
  };

  // 获取迭代翻页
  const useGetIterationPageList = async (params: {
    product_id: number;
    name?: string;
    status: number;
    page_index?: number;
  }): Promise<IterationBoardRespItem[] | null> => {
    const [err, data]: [unknown, IterationBoardRespItem[] | null] = await awaitFunc<
      { product_id: number; name?: string; status: number; page_index?: number },
      IterationBoardRespItem[]
    >({
      asyncFunc: getIterationPageListApi,
      args: params
    });
    if (err || !data) return null;
    return data;
  };
  // 更新迭代状态
  const useUpdateIterationStatus = async (params: { iteration_id: number; status: number }): Promise<boolean | null> => {
    const [err]: [unknown, ResponseParams.ResponseDataSuccess | null] = await awaitFunc<
      { iteration_id: number; status: number },
      ResponseParams.ResponseDataSuccess
    >({
      asyncFunc: updateIterationStatus,
      args: params,
      needCode: true
    });
    // 如果需要code 200
    if (typeof err === "number") {
      return err === 200;
    } else if (err) return false;
    return true;
  };

  // 获取迭代看板列表各状态数量
  const useGetiterationBoard = async (params: { product_id: number; name?: string }): Promise<IterationBoardCountResp | null> => {
    const [err, data]: [unknown, IterationBoardCountResp | null] = await awaitFunc<{ product_id: number; name?: string }, IterationBoardCountResp>({
      asyncFunc: getIterationBoardCountApi,
      args: params
    });
    if (err || !data) return null;
    return data;
  };

  return {
    useGetiterationBoard,
    useGetIterationPageList,
    useUpdateIterationStatus,
    useGetIterationBoard,
    useUpdateDemand,
    useSaveWeekReports,
    getWeekReportsList,
    getWeekReportsByWeek
  };
}
